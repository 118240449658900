import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout'
import TextBanner from '../components/text-banner/text-banner';
import ImageBanner from '../components/image-banner/image-banner';
import Multicolumn from '../components/multicolumn/multicolumn';
import OverlayBlock from '../components/overlay-block/overlay-block';

class Services extends React.Component {
    render() {
        const servicesTextBanner = get(this, 'props.data.servicesTextBanner');
        const servicesImageBannerOne = get(this, 'props.data.servicesImageBannerOne');
        const servicesImageBannerTwo = get(this, 'props.data.servicesImageBannerTwo');
        const servicesImageBannerThree = get(this, 'props.data.servicesImageBannerThree');
        const servicesImageBannerFour = get(this, 'props.data.servicesImageBannerFour');
        const servicesImageBannerFive = get(this, 'props.data.servicesImageBannerFive');
        const servicesMulticolumnHeading = get(this, 'props.data.servicesMulticolumnHeading');
        const servicesMulticolumnComponent = get(this, 'props.data.servicesMulticolumnComponent');
        const servicesOverlayBlock = get(this, 'props.data.servicesOverlayBlock');

        return (
            <Layout location={this.props.location}>
                <Seo title="Services" />
                    <div className="container-fluid container-fluid--page container-services">
                        <TextBanner textOne={servicesTextBanner.textOne} textTwo={servicesTextBanner.textTwo} textThree={servicesTextBanner.textThree} />
                        <ImageBanner headingText={servicesImageBannerOne.headingText} image={servicesImageBannerOne.backgroundImage.url} buttonPrimaryUrl={servicesImageBannerOne.buttonPrimaryUrl} buttonPrimaryText={servicesImageBannerOne.buttonPrimaryText} descriptionText={servicesImageBannerOne.descriptionText.descriptionText} />
                        <ImageBanner headingText={servicesImageBannerTwo.headingText} image={servicesImageBannerTwo.backgroundImage.url} buttonPrimaryUrl={servicesImageBannerTwo.buttonPrimaryUrl} buttonPrimaryText={servicesImageBannerTwo.buttonPrimaryText} descriptionText={servicesImageBannerTwo.descriptionText.descriptionText} />
                        <ImageBanner headingText={servicesImageBannerThree.headingText} image={servicesImageBannerThree.backgroundImage.url} buttonPrimaryUrl={servicesImageBannerThree.buttonPrimaryUrl} buttonPrimaryText={servicesImageBannerThree.buttonPrimaryText} descriptionText={servicesImageBannerThree.descriptionText.descriptionText} />
                        <ImageBanner headingText={servicesImageBannerFour.headingText} image={servicesImageBannerFour.backgroundImage.url} buttonPrimaryUrl={servicesImageBannerFour.buttonPrimaryUrl} buttonPrimaryText={servicesImageBannerFour.buttonPrimaryText} descriptionText={servicesImageBannerFour.descriptionText.descriptionText} />
                        <ImageBanner headingText={servicesImageBannerFive.headingText} image={servicesImageBannerFive.backgroundImage.url} buttonPrimaryUrl={servicesImageBannerFive.buttonPrimaryUrl} buttonPrimaryText={servicesImageBannerFive.buttonPrimaryText} descriptionText={servicesImageBannerFive.descriptionText.descriptionText} />
                        <Multicolumn multicolumnHeading={servicesMulticolumnHeading} multicolumn={servicesMulticolumnComponent.nodes} />
                        <OverlayBlock headingText={servicesOverlayBlock.title} buttonUrl={servicesOverlayBlock.buttonUrl} buttonText={servicesOverlayBlock.buttonText} image={servicesOverlayBlock.backgroundImage.url} />
                    </div>
            </Layout>
        )
    }
}

export default Services;

export const query = graphql`
query servicesQuery {
    servicesTextBanner: contentfulTextBanner(textBannerName: {eq: "servicesTextBanner"}) {
        textBannerName
        textOne
        textTwo
        textThree
    }
    servicesImageBannerOne: contentfulImageBanner(imageBannerName: {eq: "servicesImageBannerOne"}) {
        imageBannerName
        headingText
        buttonPrimaryUrl
        buttonPrimaryText
        descriptionText {
            descriptionText
        },
        backgroundImage {
            url
        }
    }
    servicesImageBannerTwo: contentfulImageBanner(imageBannerName: {eq: "servicesImageBannerTwo"}) {
        imageBannerName
        headingText
        buttonPrimaryUrl
        buttonPrimaryText
        descriptionText {
            descriptionText
        },
        backgroundImage {
            url
        }
    }
    servicesImageBannerThree: contentfulImageBanner(imageBannerName: {eq: "servicesImageBannerThree"}) {
        imageBannerName
        headingText
        buttonPrimaryUrl
        buttonPrimaryText
        descriptionText {
            descriptionText
        },
        backgroundImage {
            url
        }
    }
    servicesImageBannerFour: contentfulImageBanner(imageBannerName: {eq: "servicesImageBannerFour"}) {
        imageBannerName
        headingText
        buttonPrimaryUrl
        buttonPrimaryText
        descriptionText {
            descriptionText
        },
        backgroundImage {
            url
        }
    }
    servicesImageBannerFive: contentfulImageBanner(imageBannerName: {eq: "servicesImageBannerFive"}) {
        imageBannerName
        headingText
        buttonPrimaryUrl
        buttonPrimaryText
        descriptionText {
            descriptionText
        },
        backgroundImage {
            url
        }
    }
    servicesMulticolumnHeading: contentfulMulticolumnHeading (multicolumnHeadingName: {eq: "servicesMulticolumnHeading"}) {
        titleText
        descriptionText
    }
    servicesMulticolumnComponent: allContentfulMulticolumnComponent (sort: { fields: orderId, order: ASC }) {
        nodes {
          orderId
          headingText
          labelText
          image {
            url
          }
          descriptionText
          url
        }
      }
    servicesOverlayBlock: contentfulOverlayBlock(overlayBlockName: {eq: "servicesOverlayBlock"}) {
        overlayBlockName
        title
        buttonUrl
        buttonText
        backgroundImage {
            url
        }
    }
}
`
